import React, { useState, useRef, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import * as JsSearch from 'js-search'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import SearchIcon from '@material-ui/icons/Search'

import { PROJECT } from '../../constants/project'

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      width: '100%',
    },
    menu: {
      height: 'auto !important',
      left: `${theme.spacing(-4)}px !important`,
      maxWidth: theme.spacing(30),
      minWidth: theme.spacing(10),
      width: '100%',
      zIndex: 9999,
    },
    menuItem: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
    },
    searchIcon: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(1),
    },
    textDescription: {
      whiteSpace: 'normal',
    },
    textTitle: {
      fontWeight: 600,
      whiteSpace: 'normal',
    },
  })
)

const Search = ({
  className,
  variant,
  shrink,
  placeholder,
  innerClasses,
  label,
}) => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query SearchStrapiArticleQuery {
      allStrapiArticle {
        edges {
          node {
            id
            Title
            Description
            Text
            RouteName
            Project {
              Name
            }
            ContentBlocks {
              id
              Title
              Description
            }
          }
        }
      }
    }
  `)

  const search = useRef(new JsSearch.Search('id'))

  useEffect(() => {
    if (search.current) {
      search.current.addIndex('Title')
      search.current.addIndex('Description')

      search.current.addDocuments(
        data?.allStrapiArticle?.edges
          ?.filter(({ node }) => node.Project?.Name === PROJECT)
          .map(({ node }) => ({
            id: node.id,
            Title: node.Title,
            Description: `${node.Description} ${
              node.Text
            } <br /> ${node.ContentBlocks.reduce(
              (acc, item) =>
                `${acc} <br /> ${item.Title} <br /> ${item.Description}`,
              ''
            )}`,
            RouteName: node.RouteName,
          }))
      )
    }
  }, [data])

  const [textFieldValue, setTextFieldValue] = useState('')
  const [items, setItems] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    if (search.current) {
      setItems(
        search.current.search(textFieldValue).map(value => {
          const indexOfEntry = value.Description?.indexOf(textFieldValue)

          const text = {
            id: value.id,
            Title: value.Title,
            Description: `${
              indexOfEntry > 63 ? '...' : ''
            } ${value.Description.replace(/#/g, '')
              .replace(textFieldValue, `<b>${textFieldValue}</b>`)
              .substring(indexOfEntry - 60, indexOfEntry + 60)} ${
              indexOfEntry < value.Description.length - 63 ? '...' : ''
            }`,
            RouteName: value.RouteName,
          }

          return text
        })
      )
    }
  }, [textFieldValue])

  const handleTextFieldChange = e => {
    setAnchorEl(e.currentTarget)

    setTextFieldValue(e.currentTarget.value)

    if (e.currentTarget.value.length > 0) {
      setAnchorEl(e.currentTarget)
    } else {
      setAnchorEl(null)
    }
  }

  const handleMenuClose = () => setAnchorEl(null)

  const handleMenuItemClick = routeName => () => {
    navigate(routeName)

    setAnchorEl(null)

    setTextFieldValue('')
  }

  const renderTextField = () => {
    const inputLabelProps = { shrink }

    const inputProps = {
      startAdornment: <SearchIcon className={classes.searchIcon} />,
    }

    return (
      <TextField
        InputLabelProps={inputLabelProps}
        InputProps={inputProps}
        fullWidth={true}
        value={textFieldValue}
        onChange={handleTextFieldChange}
        label={label}
        placeholder={placeholder}
        variant={variant}
      />
    )
  }

  const popper = (
    <Popper
      className={classNames(classes.menu, innerClasses?.menu)}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      transition={true}
      placement="bottom-start"
      onClose={handleMenuClose}
    >
      <Paper>
        {items.length > 0 ? (
          items.map(value => (
            <MenuItem
              className={classes.menuItem}
              key={value.id}
              onClick={handleMenuItemClick(value.RouteName)}
            >
              <Typography className={classes.textTitle} variant="subtitle2">
                {value.Title}
              </Typography>
              <Typography
                className={classes.textDescription}
                variant="caption"
                dangerouslySetInnerHTML={{ __html: value.Description }}
              />
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled={true}>Не найдено</MenuItem>
        )}
      </Paper>
    </Popper>
  )

  return (
    <div className={classNames(classes.container, className)}>
      {renderTextField()}
      {popper}
    </div>
  )
}

Search.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
  shrink: PropTypes.bool,
  placeholder: PropTypes.string,
  innerClasses: PropTypes.shape({
    menu: PropTypes.string,
  }),
  label: PropTypes.string,
}

Search.defaultProps = {
  variant: 'standard',
  label: 'Поиск',
  placeholder: '',
}

export default Search
