import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { navigateTo } from 'gatsby'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import TreeView from '@material-ui/lab/TreeView'
import TreeItem from '@material-ui/lab/TreeItem'

const useStyles = makeStyles(theme =>
  createStyles({
    treeItemContent: {
      '&:hover': {
        color: theme.palette.primary.main,
      },

      backgroundColor: 'inherit !important',
      padding: theme.spacing(1, 0),
    },
    treeItemExpanded: {
      backgroundColor: 'inherit',
    },
    treeItemContentSection: {
      textTransform: 'uppercase',
    },
  })
)

const Menu = ({ items, onClick }) => {
  const classes = useStyles()

  const [expanded, setExpanded] = useState(
    typeof localStorage !== 'undefined'
      ? [localStorage.getItem('expandedSection')]
      : []
  )

  const handleTreeItemClick = item => () => {
    if (item?.routeName) {
      console.log(item.routeName)
      navigateTo(`/${item.routeName}`)

      onClick()
    }

    localStorage.setItem('selectedMenuItem', item.id)
  }

  const handleNodeToggle = (_, nodes) => {
    localStorage.setItem('expandedSection', nodes[0])

    setExpanded(nodes)
  }

  const renderTreeItem = item => (
    <TreeItem
      classes={{
        content: classes.treeItemContent,
        expanded: classes.treeItemExpanded,
        label: classNames({
          [classes.treeItemContentSection]: Array.isArray(item?.items),
        }),
      }}
      key={item.id}
      nodeId={String(item.id)}
      onClick={handleTreeItemClick(item)}
      label={item.title}
    >
      {item?.items?.map(renderTreeItem)}
    </TreeItem>
  )

  return (
    <TreeView expanded={expanded} onNodeToggle={handleNodeToggle}>
      {items.map(renderTreeItem)}
    </TreeView>
  )
}

Menu.defaultProps = {
  items: [],
}

Menu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          routeName: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  onClick: PropTypes.func.isRequired,
}

export default Menu
