import PropTypes from 'prop-types'
import React from 'react'

const Header = ({ className, children }) => (
  <header className={className}>{children}</header>
)

Header.propTypes = {
  className: PropTypes.string,
}

export default Header
