import createMuiTheme from '@material-ui/core/styles/createMuiTheme'

import {
  primaryDark,
  primaryLight,
  primaryMain,
  seconadryDark,
  secondaryMain,
  secondaryLight,
  textPrimary,
  textSecondary,
} from 'oc-theme/lib'

export const theme = createMuiTheme({
  palette: {
    primary: {
      dark: primaryDark,
      light: primaryLight,
      main: primaryMain,
    },
    secondary: {
      dark: seconadryDark,
      light: secondaryLight,
      main: secondaryMain,
    },
    text: {
      primary: textPrimary,
      secondary: textSecondary,
    },
  },
})
